export const SSL_TEST = '48ed36cd-59a99ce5';
export const SSL_PROD = '4f7e3f09-59fb2fa3';

export const APPLE_API_KEY_TEST = 'video.wave-test.signin.service';
export const APPLE_API_KEY_PROD = 'video.wave.signin.service';
export const APPLE_AUTH_URL_TEST = 'https://wave-test.video/callback/aplleid';
export const APPLE_AUTH_URL_PROD = 'https://wave.video/callback/aplleid';

export const GOOGLE_API_KEY_TEST = '161719605837-8ufequ85vtff9l091eqm5oe2s6ks6dp9.apps.googleusercontent.com';
export const GOOGLE_API_KEY_PROD = '207493817854-9jf35sq1manvf44u6dhsb4n9ffc0sji4.apps.googleusercontent.com';
export const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/auth';
export const GOOGLE_ANALYTIC_WAVE_TEST = 'UA-28911167-8';
export const GOOGLE_ANALYTIC_WAVE_PROD = 'UA-28911167-6';
export const GOOGLE_CAPTCHA_KEY = '6LflXqoUAAAAAPNzxj3WnPQlqEXxBMcrVIN2N8XC';

export const TWITTER_AUTH_URL = 'authenticate/twitter';

export const FACEBOOK_AUTH_URL = 'https://www.facebook.com/dialog/oauth';
export const FACEBOOK_API_KEY_TEST = '666402083370003';
export const FACEBOOK_API_KEY_PROD = '286295968111267';
export const FACEBOOK_ANALYTIC_WAVE_TEST = '1138773526252594';
export const FACEBOOK_ANALYTIC_WAVE_PROD = '1580851972146505';

export const WISTIA_AUTH_URL = 'https://api.wistia.com/v1/';
export const WISTIA_API_KEY_PROD = '837a043a64bd68d23730dcc6dcaad4ea473b926011b9c40f98ce15c4e0b133d3';

export const MIXPANEL_CUSTOM_LIB_URL = 'https://metrics.animatron.com/mp';
export const MIXPANEL_ANALYTIC_API_HOST = 'util/mp';

export const MIXPANEL_ANALYTIC_WAVE_TEST = 'b45044409ac778ee85d4da0a3cc6ba04';
export const MIXPANEL_ANALYTIC_WAVE_PROD = '1da7a894c2f0d0952209dbb88ef1ef59';
export const MIXPANEL_ANALYTIC_STUDIO_PROD = '6b7ab79f30917de06b3f8112881e1b16';

export const FULLSTORY_ANALYTIC_TEST = 'DQCPR';
export const FULLSTORY_ANALYTIC_PROD = 'DPR69';

export const HOTJAR_ANALYTIC = '1531067';

export const INTERCOM_WAVE_TEST = 'nmrm9rdp';
export const INTERCOM_WAVE_PROD = '93c0bf3bdc0786f3e0f9eae1ce240e8bc01833da';
export const INTERCOM_STUDIO_TEST = 'n95k1p6d';
export const INTERCOM_STUDIO_PROD = 'lmalds3q';

export const AFFILIATE_TAP_ID_TEST = '4717-bb729d';
export const AFFILIATE_TAP_ID_PROD = '4142-17375a';

export const USERPILOT_KEY = '59qn95s10';

export const WAVE_BUTTON_API_KEY_TEST = '0769dd5d8eb64e2e93c1a76e';
export const WAVE_BUTTON_API_KEY_PROD = 'b662dd5dff2fbc7f1fbc6eae';

export const STRIPE_LIB_URL = 'https://js.stripe.com/v2/';
export const STRIPE_KEY_TEST = 'pk_test_xoWbjuhXjp1X4rhEOyLpwOaO';
export const STRIPE_KEY_PROD = 'pk_live_DhthPJdfMdVwEK7s9TNq1Wct';

export const SENTRY_AUTH_TOKEN = '1d93ff28a44e4c3aaaaff854277cd8583c1de670bd8744bc895670dfd9300840';

export const SEMRUSH_APP_ID_TEST = 'fc26d85a-cf6e-4acb-ac36-7e92d323d3f1';
export const SEMRUSH_APP_ID_PROD = '6a2f4e72-3b8f-488a-a72d-38548b4f86a7';
export const SEMRUSH_TEST_APP_URL = 'https://www.semrush.com/apps/wave-video-test';
export const SEMRUSH_PROD_APP_URL = 'https://www.semrush.com/apps/wave-video';

export const TERMLY_ID = '7ede0480-caef-437a-b99b-560bbc62446d';

export const GOOGLE_ACCOUNT_TEST_API_KEY = 'AIzaSyC68OU83iuJexqDws-rtJLesYqsiFMdxPo';
export const GOOGLE_ACCOUNT_PROD_API_KEY = 'AIzaSyC68OU83iuJexqDws-rtJLesYqsiFMdxPo';

export const GOOGLE_ACCOUNT_TEST_CLIENT_ID = '207493817854-9jf35sq1manvf44u6dhsb4n9ffc0sji4.apps.googleusercontent.com';
export const GOOGLE_ACCOUNT_PROD_CLIENT_ID = '207493817854-9jf35sq1manvf44u6dhsb4n9ffc0sji4.apps.googleusercontent.com';