import noop from '@anm/helpers/noop';

import {
  ONBOARDING_TWO_OPTIONS_CLOSE,
  ONBOARDING_TWO_OPTIONS_OPEN_EDITOR,
  ONBOARDING_TWO_OPTIONS_OPEN_STUDIO,
  ONBOARDING_TWO_OPTIONS_SHOW
} from './mixpanelAnalytic';

declare global {
  interface Window {
    userpilot: any;
  }
}

const track = (...args: unknown[]) => window.userpilot?.track(...args) || noop;

export const trackOnBoardTwoOptionsShow = () => {
  track(ONBOARDING_TWO_OPTIONS_SHOW);
};
export const trackOnBoardTwoOptionsOpenStudio = () => {
  track(ONBOARDING_TWO_OPTIONS_OPEN_STUDIO);
};
export const trackOnBoardTwoOptionsOpenEditor = () => {
  track(ONBOARDING_TWO_OPTIONS_OPEN_EDITOR);
};
export const trackOnBoardTwoOptionsClose = () => {
  track(ONBOARDING_TWO_OPTIONS_CLOSE);
};
