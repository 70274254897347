import { AUTH_COOKIE } from '@anm/constants/auth';
import {
  ANIMATRON_LIBRARY_TEST,
  ANIMATRON_TEST,
  WAVE_API_TEST,
  WAVE_TEST,
  YOUTUBE_API_TEST
} from '@anm/constants/domains';
import {
  AFFILIATE_TAP_ID_TEST,
  FACEBOOK_ANALYTIC_WAVE_TEST,
  FACEBOOK_API_KEY_TEST,
  FACEBOOK_AUTH_URL,
  FULLSTORY_ANALYTIC_TEST,
  GOOGLE_ANALYTIC_WAVE_TEST,
  GOOGLE_API_KEY_TEST,
  GOOGLE_AUTH_URL,
  HOTJAR_ANALYTIC,
  INTERCOM_WAVE_TEST,
  MIXPANEL_ANALYTIC_API_HOST,
  MIXPANEL_ANALYTIC_WAVE_TEST,
  MIXPANEL_CUSTOM_LIB_URL,
  TWITTER_AUTH_URL,
  WAVE_BUTTON_API_KEY_TEST,
  WISTIA_API_KEY_PROD,
  WISTIA_AUTH_URL
} from '@anm/constants/services';
import { STREAMING_STUDIO_URL_DEV } from '@anm/constants/streaming';
import getDomainNameFromUrl from '@anm/helpers/get/getDomainNameFromUrl';
import { animatronSecureWord } from '@anm/token-sharing';

import { Config } from './types';

const defaultEnv: Config = {
  auth: {
    cookieName: AUTH_COOKIE,
    userLocalStorage: `${animatronSecureWord}:user`,
    authLocalStorage: `${animatronSecureWord}:auth`,
    securityToken: 'Animatron-Security-Token'
  },
  port: 3003,
  cdnUrl: '/',
  isProd: false,
  apiUrl: WAVE_API_TEST,
  waveUrl: WAVE_TEST,
  studioUrl: ANIMATRON_TEST,
  streamingStudioUrl: STREAMING_STUDIO_URL_DEV,
  editorUrl: `${WAVE_TEST}editor`,
  calendarData: ANIMATRON_LIBRARY_TEST,
  youtubeApiUrl: YOUTUBE_API_TEST,
  buttonEmbedUrl: `${WAVE_TEST}editor/embed.js`,
  nextDevMode: true,
  waveButtonApiKey: WAVE_BUTTON_API_KEY_TEST,
  sentry: {
    dsn: 'https://51c50ad5f0fa4eb78071d7be197ca431@o1082595.ingest.sentry.io/4503898148438016',
    release: 'landings',
    denyUrls: ['convert/', 'youtube-dl']
  },
  support: {
    intercom: {
      id: INTERCOM_WAVE_TEST,
      enable: false
    }
  },
  services: {
    wistia: {
      apiKey: WISTIA_API_KEY_PROD,
      oauthUrl: WISTIA_AUTH_URL
    },
    google: {
      apiKey: GOOGLE_API_KEY_TEST,
      oauthUrl: GOOGLE_AUTH_URL
    },
    twitter: {
      oauthUrl: TWITTER_AUTH_URL
    },
    facebook: {
      apiKey: FACEBOOK_API_KEY_TEST,
      oauthUrl: FACEBOOK_AUTH_URL
    }
  },
  analytics: {
    google: {
      enable: false,
      id: GOOGLE_ANALYTIC_WAVE_TEST,
      domain: getDomainNameFromUrl(ANIMATRON_TEST)
    },
    facebook: {
      enable: false,
      id: FACEBOOK_ANALYTIC_WAVE_TEST
    },
    mixpanel: {
      enable: false,
      id: MIXPANEL_ANALYTIC_WAVE_TEST,
      libUrl: MIXPANEL_CUSTOM_LIB_URL,
      apiHost: WAVE_API_TEST + MIXPANEL_ANALYTIC_API_HOST
    },
    fullstory: {
      enable: false,
      id: FULLSTORY_ANALYTIC_TEST
    },
    hotjar: {
      enable: false,
      id: HOTJAR_ANALYTIC
    }
  },
  affiliates: {
    tap: {
      enable: true,
      id: AFFILIATE_TAP_ID_TEST
    }
  },
  embed: {
    analytics: {
      google: {
        enable: false,
        id: GOOGLE_ANALYTIC_WAVE_TEST
      },
      facebook: {
        enable: false,
        id: FACEBOOK_ANALYTIC_WAVE_TEST
      },
      mixpanel: {
        enable: false,
        id: MIXPANEL_ANALYTIC_WAVE_TEST
      },
      fullstory: {
        enable: false,
        id: FULLSTORY_ANALYTIC_TEST
      },
      hotjar: {
        enable: false,
        id: HOTJAR_ANALYTIC
      }
    }
  },
  langs: {
    languages: ['de', 'pt', 'br', 'ca', 'uk']
  },
  supportedVideoExtensions: ['mp4', 'avi', 'mov', 'm4v', 'wmv', 'mkv', 'webm'],
  supportedImageExtensions: ['jpg', 'jpeg', 'png'],
  supportedAudioExtensions: ['m4a', 'mp3', 'ogg', 'aac', 'aiff', 'wav'],
  guestLimits: {
    maxVideoFromAI: 2
  }
};

export default defaultEnv;
